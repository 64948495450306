import alertInfo from '@comp/alert_info';
import basicUpload from '@comp/basic_upload';
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {
        alertInfo,
        basicUpload
    },
    props: {
        itemId: {
            type: String,
            default: '1'
        }
    },
    computed: {
        ...mapGetters([
            'heritageId',
            'userName',
            'userId'
        ])
    },
    data() {
        // 记录id
        return {
            id: '',
            type: '',
            formdata: {},
            isEdit: true, // 是否可编辑
            isAdd: true, // 是否新增
            basicType: ''
        };
    },
    created() {
        this.id = this.$route.query.id;
        this.type = this.$route.query.type;
        this.basicType = this.$route.query.basicType;
    },
    methods: {
        ...mapActions([
            'SaveData',
            'GetDefinitionAndNote',
            'GetDetailsData'
        ]),
        async getFromData() {
            let params = {
                itemid: this.formdata.itemID,
                ID: this.id,
            };
            let result = await this.GetDetailsData(params);
            if (result && result.length > 0) {
                Object.assign(this.formdata.saveJson, result[0]);
            }
        },
        changeSJMJ(value) {
            if (value === '不公开') {
                this.showBackBtn();
                this.saveButton = false;
            } else {
                this.showAllBtn();
                this.saveButton = true;
            }
        },
        // 控制按钮显示 查看时只显示返回按钮 编辑以及新建时 显示全部按钮
        contralBtn() {
            if (this.isEdit) {
                this.showAllBtn();
            } else {
                this.showBackBtn();
            }
        },
        // 只显示 返回按钮
        showBackBtn() {
            this.snButton.buttonData.forEach(item => {
                if (item.name !== '返回') {
                    item.isShow = false;
                }
            });
        },
        // 显示全部按钮
        showAllBtn() {
            this.snButton.buttonData.forEach(item => {
                item.isShow = true;
            });
        },
        handleChange(value) {
            this[value]();
        },
        dealFromData() {
            let fromdata = this.formdata;
            fromdata.heritageId = this.heritageId;
            fromdata.userName = this.userName;
            fromdata.userId = this.userId;
        },
        // 注意特殊字符处理
        // 保存暂不提交未做验证
        async saveNosubmit() {
            this.dealFromData();
            this.formdata.saveJson.shzt = 1;

            let basicValidPromise = this.$refs.formdata.validate();
            Promise.all([
                basicValidPromise
                // eslint-disable-next-line no-unused-vars
            ]).then(([basicValid]) => {
                this.saveData();
            }).catch(() => {
                this.$message({
                    type: 'warning',
                    message: '请检查是否填写完整！'
                });
            });
        },
        // 保存并提交
        async saveSubmit() {
            let basicValidPromise = this.$refs.formdata.validate();
            Promise.all([
                basicValidPromise
                // eslint-disable-next-line no-unused-vars
            ]).then(([basicValid]) => {
                this.saveForm();
            }).catch(() => {
                this.$message({
                    type: 'warning',
                    message: '请检查是否填写完整！'
                });
            });
        },
        saveForm() {
            this.$confirm('数据提交后不能更改，你确认要提交吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.dealFromData();
                this.formdata.saveJson.shzt = 2;
                this.saveData();
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消提交'
                });
            });
        },
        async saveData() {
            let res = await this.SaveData({
                data: this.formdata.saveJson,
                itemid: this.itemID
            });
            if (res.IsSuccess) {
                this.$message({
                    type: 'success',
                    message: '保存成功'
                });
                this.returnList();
            }
        },
        // 返回列表
        returnList() {
            this.$router.go(-1);
        },
    },
    mounted() {
        if (this.id) {
            this.getFromData();
        }
        this.snButton.buttonData.forEach(item => {
            item.isShow = true;
        });
        // type: 0 => 新建; 1 => 编辑; 2 => 查看
        this.isEdit = this.type === '0' || this.type === '1';
        this.isAdd = this.type === '0';
        this.contralBtn();
    }
};