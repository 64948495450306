<template>
    <div class="expertPage scrollbar">
        <!-- 按钮 -->
        <div class="expert_btn">
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton" @handleChange="handleChange"></sn-button>
                </div>
            </div>
        </div>
        <div class="expert_edit">
            <el-form :model="formdata.saveJson" ref="formdata" label-width="120px" class="demo-ruleForm">
                <table>
                    <tbody>
                        <tr>
                            <td colspan="5">
                                <label class="expert_title">任务记录单</label>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <label for="RWMC">
                                    <i>*</i>任务名称</label>
                            </td>
                            <td colspan="3">
                                <el-input :disabled="!isEdit" v-model="formdata.saveJson.RWMC" onkeydown="if(event.keyCode==32) return false"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <label for="RWYXJB">
                                    <i>*</i>任务优先级别</label>
                            </td>
                            <td colspan="3">
                                <el-select v-model="formdata.saveJson.RWYXJB" placeholder="请选择任务优先级别" :disabled="!isEdit" filterable>
                                    <el-option v-for="(item,index) in rwyxjbList" :key="`${item.Code}-${index}`" :label="item.Name" :value="item.Code"></el-option>
                                </el-select>
                            </td>
                        </tr>
                        <!-- 任务生成时间：新建不可见，修改/删除可见，不可编辑 -->
                        <tr>
                            <td colspan="2">
                                <label for="RWSCSJ">
                                    <i>*</i>任务生成时间</label>
                            </td>
                            <td colspan="3">
                                <el-date-picker class="expert_edit_date" :editable="false" :disabled="!isEdit" v-model="formdata.saveJson.RWSCSJ" type="datetime" placeholder="选择日期" :picker-options="rwscsjPickerOptions" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                            </td>
                        </tr>
                        <tr v-if="!isAdd">
                            <td colspan="2">
                                <label for="PGSJ">任务返回时间</label>
                            </td>
                            <td colspan="3">
                                <el-date-picker class="expert_edit_date" :editable="false" :disabled="true" v-model="formdata.saveJson.PGSJ" type="datetime" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                            </td>
                        </tr>
                        <tr v-if="!isAdd">
                            <td colspan="2">
                                <label for="CJRIDXM">
                                    任务创建人</label>
                            </td>
                            <td colspan="3">
                                <label class="col-label">{{formdata.saveJson.CJRIDXM}}</label>
                            </td>
                        </tr>
                        <tr v-if="!isAdd">
                            <td colspan="2">
                                <label for="BMMC">
                                    所属部门</label>
                            </td>
                            <td colspan="3">
                                <label class="col-label">{{formdata.saveJson.BMMC}}</label>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <label for="SZDW">
                                    <i>*</i>专家单位</label>
                            </td>
                            <td colspan="3">
                                <el-select v-model="formdata.saveJson.SZDW" placeholder="请选择专家单位" :disabled="!isEdit" filterable @change="handleZjdwChanged" clearable>
                                    <el-option v-for="(item,index) in zjdwList" :key="`${item.SZDW}-${index}`" :label="item.SZDW" :value="item.SZDW"></el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <label for="ZJID">
                                    <i>*</i>专家姓名</label>
                            </td>
                            <td colspan="3">
                                <el-select v-model="formdata.saveJson.ZJID" placeholder="请选择专家姓名" :disabled="!isEdit" filterable @change="handleZjxmChanged">
                                    <el-option v-for="(item,index) in zjxmList" :key="`${item.Id}-${index}`" :label="item.Zjxm" :value="item.Id"></el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="5">
                                <label class="expert_title">任务工作表</label>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <label for="RWMS">
                                    <i>*</i>任务描述</label>
                            </td>
                            <td colspan="3">
                                <el-input type="textarea" :rows="6" :disabled="!isEdit" v-model="formdata.saveJson.RWMS" onkeydown="if(event.keyCode==32) return false"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <label for="JCDXBID">
                                    <i>*</i>任务对象类型</label>
                            </td>
                            <td colspan="3">
                                <el-radio-group v-model="formdata.saveJson.JCDXBID" @change="handleRwdxlxChanged">
                                    <el-radio :disabled="!isEdit" v-for="item in rwdxlxList" :key="item.Id" :label="item.Id">{{item.MC}}</el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <label for="JCDXID">
                                    <i>*</i>任务对象</label>
                            </td>
                            <td colspan="3">
                                <el-cascader style="width: 100%" v-show="formdata.saveJson.JCDXBID === '7'" :options="rwdxList" :props="props" v-model="ycysArrValue" placeholder="请选择任务对象" :disabled="!isEdit" filterable @change="handleYcysChanged"></el-cascader>
                                <el-select v-show="formdata.saveJson.JCDXBID !== '7'" v-model="formdata.saveJson.JCDXID" placeholder="请选择任务对象" :disabled="!isEdit" filterable @change="handleJcdxidChanged">
                                    <el-option v-for="(item,index) in rwdxList" :key="`${item.ID}-${index}`" :label="item.MC" :value="item.ID"></el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <td rowspan="2" colspan="1">相关文件上传</td>
                            <td colspan="1">
                                <label>监测数据汇总</label>
                            </td>
                            <!-- 查看 -->
                            <td class="file-td" colspan="3" v-if="isEdit == false">
                                <div v-if="jcsjhzFileList && jcsjhzFileList.length>0">
                                    <div class="file-td-single" v-for="(item,index) in jcsjhzFileList" :key="index">
                                        <a class="assess-a" :href="item.url" target="_blank">{{item.name}}</a>
                                    </div>
                                </div>
                                <el-input v-else :disabled="false" placeholder="未上传"></el-input>
                            </td>
                            <td class="file-upload-td" colspan="3" v-else>
                                <ExpertUpload @successUpload="successUpload" @handleDelFile="handleDelFile" :fileList="jcsjhzFileList" fileIndex="1"></ExpertUpload>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1">
                                <label>其他相关资料</label>
                            </td>
                            <!-- 查看 -->
                            <td class="file-td" colspan="3" v-if="isEdit == false">
                                <div v-if="qtxgzlFileList && qtxgzlFileList.length>0">
                                    <div class="file-td-single" v-for="(item,index) in qtxgzlFileList" :key="index">
                                        <a class="assess-a" :href="item.url" target="_blank">{{item.name}}</a>
                                    </div>
                                </div>
                                <el-input v-else :disabled="false" placeholder="未上传"></el-input>
                            </td>
                            <td class="file-upload-td" colspan="3" v-else>
                                <ExpertUpload @successUpload="successUpload" @handleDelFile="handleDelFile" :fileList="qtxgzlFileList" fileIndex="2"></ExpertUpload>
                            </td>
                        </tr>
                        <tr>
                            <td class="empty-td" colspan="1">
                            </td>
                            <td class="empty-td" colspan="1">
                            </td>
                            <td class="empty-td" colspan="1">
                            </td>
                            <td class="empty-td" colspan="1">
                            </td>
                            <td class="empty-td" colspan="1">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../edit-button.js";
import infoMixin from "../info_mixin.js";
import { mapActions } from "vuex";
import ExpertUpload from "../components/expert-upload"
export default {
    name: "consulting_expert_form",
    mixins: [infoMixin],
    data() {
        return {
            snButton: snButton,
            formdata: {
                saveJson: {
                    RWMC: '',
                    RWYXJB: '',
                    RWSCSJ: '',
                    PGSJ: '',
                    CJRIDXM: '',
                    BMMC: '',
                    SZDW: '',
                    ZJID: '',
                    RWMS: '',
                    JCDXBID: '',
                    JCDXID: '',
                    JCDXMC: '',
                    JJCD: '', //紧急程度
                    RWZT: '',
                },
                heritageId: "",
                itemID: "",
                userName: "",
                userId: ""
            },
            jcsjhzFileList: [], //监测数据汇总文件列表
            qtxgzlFileList: [], //其他相关资料文件列表
            fjids: '',
            otherfjids: '',
            itemID: "",
            rwyxjbList: [], //任务优先级别列表
            zjdwList: [], //专家单位列表
            zjxmList: [], //专家姓名列表（按"专家单位"筛选）
            rwdxlxList: [], //任务对象类型列表
            rwdxList: [], //任务对象列表（按"任务对象类型"筛选）
            rwscsjPickerOptions: {
                disabledDate(time) {
                    let date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    return time.getTime() < date;
                },
            },
            ycysArrValue: [],
            props: {
                checkStrictly: true,
                value: 'ID',
                label: 'MC',
                children: 'Children'
            }
        };
    },
    components: {
        ExpertUpload
    },
    mounted() {
        if (this.isAdd) {
            this.formdata.saveJson.CJRID = localStorage.userId || ''; //获取登录人员信息
        }
        this.getRwyxjbList();
        this.getZjdwList();
        this.getRwdxlxList();

        // eslint-disable-next-line no-undef
        $(document).ready(function() {
            let tmp = document.getElementsByClassName("el-textarea__inner");
            if (tmp && tmp.length > 0 && tmp[0].className) {
                tmp.forEach(item => {
                    item.className = "el-textarea__inner scrollbar";
                })
            }
        });
    },
    methods: {
        ...mapActions([
            "GetEnumItem", //获取枚举
            "GetExpertCompanyList", //获取专家单位列表服务
            "GetExpertNameList", //获取专家姓名列表服务
            "GetTaskObjectType", //获取任务对象类型服务
            "GetTaskObjectList", //获取任务对象列表服务
            "GetTaskInfoDetails", //获取任务信息详情服务
            "SaveTaskInfo", //保存任务信息服务
            "DelFileInfo", //删除附件
        ]),
        async getFromData() {
            this.jcsjhzFileList = [];
            this.qtxgzlFileList = [];
            let params = {
                // itemid: this.formdata.itemID,
                rwid: this.id
            };
            let result = await this.GetTaskInfoDetails(params);
            if (result) {
                if (result.result && result.result.length > 0) {
                    Object.assign(this.formdata.saveJson, result.result[0]);
                    this.getZjxmList();
                    if (this.formdata.saveJson.JCDXBID) {
                        this.getRwdxList();
                    }
                    if(this.formdata.saveJson.JCDXID){
                        this.ycysArrValue = this.formdata.saveJson.JCDXID;
                    }
                }
                if (result.file && result.file.length > 0) {
                    result.file.forEach(item => {
                        this.jcsjhzFileList.push({
                            Guid: item.ID,
                            name: item.WDMC,
                            url: item.CCLJ,
                        })
                    })
                }
                if (result.otherfile && result.otherfile.length > 0) {
                    result.otherfile.forEach(item => {
                        this.qtxgzlFileList.push({
                            Guid: item.ID,
                            name: item.WDMC,
                            url: item.CCLJ,
                        })
                    })
                }
            }
        },
        // 获取任务优先级别列表
        async getRwyxjbList() {
            this.rwyxjbList = [];
            let da = await this.GetEnumItem({
                Enumid: '5f00859b-70d2-11ea-bd8d-000c2977b7fd'
            })
            if (da && da.length > 0) {
                this.rwyxjbList = da;
            }
        },
        // 获取专家单位列表
        async getZjdwList() {
            this.zjdwList = [];
            let da = await this.GetExpertCompanyList();
            if (da && da.length > 0) {
                this.zjdwList = da;
            }
        },
        // 获取专家姓名列表（按"专家单位"筛选）
        async getZjxmList() {
            this.zjxmList = [];
            let zjdwID = this.formdata.saveJson.SZDW || '';
            let da = await this.GetExpertNameList({
                CompanyName: zjdwID
            });
            if (da && da.length > 0) {
                this.zjxmList = da;
            }
        },
        // 选择专家单位
        // eslint-disable-next-line no-unused-vars
        handleZjdwChanged(val) {
            this.getZjxmList();
            this.formdata.saveJson.ZJID = '';
        },
        // 选择专家姓名
        handleZjxmChanged(val) {
            console.log(33333, val)
            this.formdata.saveJson.SZDW = '';
            if (this.zjxmList && this.zjxmList.length > 0) {
                this.zjxmList.forEach(item => {
                    if (val === item.Id) {
                        this.formdata.saveJson.SZDW = item.Szdw;
                        return;
                    }
                })
            }
        },
        // 获取任务对象类型列表
        async getRwdxlxList() {
            this.rwdxlxList = [];
            this.formdata.saveJson.JCDXBID = '';
            let da = await this.GetTaskObjectType();
            if (da && da.length > 0) {
                this.rwdxlxList = da;
                this.formdata.saveJson.JCDXBID = da[0].Id;
                this.getRwdxList();
            }
        },
        // 选择任务对象类型
        // eslint-disable-next-line no-unused-vars
        handleRwdxlxChanged(val) {
            this.getRwdxList();
            this.formdata.saveJson.JCDXID = '';
            this.formdata.saveJson.JCDXMC = '';
        },
        // 获取任务对象列表（按"任务对象类型"筛选）
        async getRwdxList() {
            this.rwdxList = [];
            let rwdxlx = this.formdata.saveJson.JCDXBID;
            let da = await this.GetTaskObjectList({
                jcdxbid: rwdxlx
            });
            if (da && da.length > 0) {
                this.rwdxList = da;
            }
        },
        // 选择任务对象
        handleYcysChanged(val) {
            let ycysId = val[val.length - 1];
            this.formdata.saveJson.JCDXID = ycysId;
            this.setYcysMc(ycysId, this.rwdxList);
        },
        setYcysMc(ycysId, val){
            if(val && val.length>0){
                val.forEach(item=>{
                    if(ycysId === item.ID){
                        this.formdata.saveJson.JCDXMC = item.MC;
                    }else if(item.Children && item.Children.length > 0) {
                        this.setYcysMc(ycysId, item.Children);
                    }
                })
            }
        },
        // 选择任务对象
        handleJcdxidChanged(val) {
            if (this.rwdxList && this.rwdxList.length > 0) {
                this.rwdxList.forEach(item => {
                    if (val === item.ID) {
                        this.formdata.saveJson.JCDXMC = item.MC;
                    }
                })
            }
        },
        successUpload(val, fileIndex) {
            if (fileIndex === '1') {
                this.fjids = val;
            } else if (fileIndex === '2') {
                this.otherfjids = val;
            }
        },
        handleDelFile(val, fileIndex) {
            let fjids = '';
            let canDelFileList = [];
            if (fileIndex === '1') {
                canDelFileList = this.jcsjhzFileList;
            } else if (fileIndex === '2') {
                canDelFileList = this.qtxgzlFileList;
            }

            if (canDelFileList && canDelFileList.length > 0) {
                canDelFileList.forEach(item => {
                    if (item.Guid === val) {
                        fjids = val;
                    }
                })
            }
            this.delFile(fjids);

        },
        async delFile(fjids) {
            let result = await this.DelFileInfo({
                fjids: fjids
            });
            if (result.IsSuccess) {
                this.$message({
                    type: 'success',
                    message: '移除成功'
                });
            } else {
                this.$message({
                    type: 'error',
                    message: '移除失败'
                });
            }
        },
        // 保存暂不提交未做验证
        saveNosubmit() {
            this.formdata.saveJson.shzt = 1;
            this.formdata.saveJson.JJCD = '0';
            this.formdata.saveJson.RWZT = '0';
            this.checkBeforeSave('1');
        },
        // 保存并提交
        async saveSubmit() {
            this.checkBeforeSave('2');
        },
        // 必填验证
        checkBeforeSave(val) {
            let formName = 'formdata';
            //保存专业人员信息
            let _this = this;
            _this.$refs[formName].validate(valid => {
                if (
                    valid &&
                    _this.formdata.saveJson.RWMC != "" &&
                    _this.formdata.saveJson.RWYXJB != "" &&
                    _this.formdata.saveJson.RWSCSJ &&
                    _this.formdata.saveJson.SZDW != "" &&
                    _this.formdata.saveJson.ZJID != "" &&
                    _this.formdata.saveJson.RWMS != "" &&
                    _this.formdata.saveJson.JCDXBID != "" &&
                    _this.formdata.saveJson.JCDXID != ""
                ) {
                    if (val === '1') {
                        this.saveData();
                    } else if (val === '2') {
                        this.saveForm();
                    }

                } else if (_this.formdata.saveJson.RWMC == "") {
                    _this.showMsg("请输入任务名称", "error")
                } else if (_this.formdata.saveJson.RWYXJB == "") {
                    _this.showMsg("请选择任务优先级别", "error")
                } else if (!_this.formdata.saveJson.RWSCSJ) {
                    _this.showMsg("请选择任务生成时间", "error")
                } else if (_this.formdata.saveJson.SZDW == "") {
                    _this.showMsg("请选择专家单位", "error")
                } else if (_this.formdata.saveJson.ZJID == "") {
                    _this.showMsg("请选择专家姓名", "error")
                } else if (_this.formdata.saveJson.RWMS == "") {
                    _this.showMsg("请输入任务描述", "error")
                } else if (_this.formdata.saveJson.JCDXBID == "") {
                    _this.showMsg("请选择任务对象类型", "error")
                } else if (_this.formdata.saveJson.JCDXID == "") {
                    _this.showMsg("请选择任务对象", "error")
                } else {
                    return false
                }
            })
        },
        showMsg(msg, state) {
            //信息提示
            this.$message({
                showClose: true,
                message: msg,
                type: state,
                duration: 2000
            })
        },
        saveForm() {
            this.$confirm('数据提交后不能更改，你确认要提交吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.formdata.saveJson.shzt = 2;
                this.formdata.saveJson.JJCD = '0';
                this.formdata.saveJson.RWZT = '1';
                this.saveData();
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消提交'
                });
            });
        },
        async saveData() {
            let res = await this.SaveTaskInfo({
                strjson: this.formdata.saveJson,
                fjids: this.fjids,
                otherfjids: this.otherfjids,
            });
            if (res.IsSuccess) {
                this.$message({
                    type: 'success',
                    message: '保存成功'
                });
                this.returnList();
            }
        },
    }
};
</script>
<style scoped>
.expertPage {
    position: absolute;
    top: 72px;
    left: 120px;
    width: calc(100% - 140px);
    height: calc(100% - 72px);
    overflow-y: auto;
    user-select: none;
}

.expert_btn {
    position: absolute;
    top: 35px;
    right: 50px;
    z-index: 500;
}

.expert_edit {
    width: 50%;
    min-width: 960px;
    margin: 0 auto;
    padding-top: 40px;
}

.expert_edit .el-input__inner {
    border: 1px solid transparent !important;
}

.expert_title {
    width: calc(100% + 2px);
    height: 45px;
    margin-left: -1px;
    line-height: 45px;
    font-size: 16px;
    color: #23468c;
    background-color: #d6dfcc;
    font-weight: 700;
    letter-spacing: 3px;
    text-align: center;
}

.demo-ruleForm table {
    width: 100%;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

table td {
    width: 20%;
    border-right: 1px solid #ddd;
    height: 40px;
    font-size: 14px;
    padding: 0;
    text-align: center;
}

tr:nth-child(odd) {
    background: #f7f9f4 !important;
}

label i {
    color: red;
    margin-right: 5px;
    font-style: normal;
    font-weight: 700;
}

.expert_edit .el-date-editor.el-input,
.expert_edit .el-select {
    width: 100%;
}
.expert_edit_date.el-date-editor.el-input {
    padding-left: 20px;
}

.el-radio-group {
    width: 100%;
    padding: 10px 0;
}

.el-radio {
    margin: 0 8px;
    float: left;
}

.demo-ruleForm .first_span {
    right: 105px !important;
}

td {
    position: relative;
}

.col-label {
    width: 100%;
    text-align: left;
    padding: 0 10px;
}

.file-td {
    text-align: left;
    padding: 10px;
}

.file-td-single {
    padding: 2px;
}

.file-upload-td {
    text-align: left;
    padding: 10px;
}

.assess-a {
    color: #00f;
    cursor: pointer;
}

.empty-td {
    height: 0px
}
</style>